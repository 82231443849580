.vsp-c-data-subject-form,
.vsp-c-data-subject-access-request,
.vsp-c-data-subject-withdrawal-form {
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: 50vh;
    padding: 4rem;

    h1 {
        font-size: 4rem;
        font-weight: 300;

        @media (max-width: $screen-md) {
            font-size: 2.5rem;
        }
    }

}
.vsp-c-data-subject-access-request,
.vsp-c-data-subject-form   {

    h1 {
        padding-top: 4rem;
    }

    .vsp-c-btn {
        width: 17rem;
        align-self: center;
        margin-top: 20vh;

        @media (max-width: $screen-md) {
            margin-top: 15vh;
        }
    }
}

.vsp-c-data-subject-access-request {
    min-height: 70vh;
}

.vsp-c-data-subject-withdrawal-form {
    align-items: center;
    padding: 0 3rem;

    h1 {
        padding: 4rem 0 2rem 0;
    }

    .vsp-c-data-subject-withdrawal-form__content {
        text-align: left;
        max-width: 60rem;

        p {
            padding: 1.4rem 0;
        }

        li {
            font-weight: 300;
        }
    }

    input:first-child {
        font-size: 1rem;
        border: 1px solid $vsp-color-gray-lighter;
        outline: none;
        padding: .7rem 1.2rem;
        border-radius: 2rem;
        color: $vsp-color-base;
        background-color: $vsp-color-white;
        width: 13rem;

        &::placeholder {
            color: $vsp-color-gray;
            font-weight: 300;
        }
    }

    .vsp-c-btn {
        margin-bottom: 5rem;
        margin-top: 1rem;
        line-height: 1;
        font-size: 1rem;
        width: 9rem;
        border: none;
    }
}