.vsp-c-terms-and-conditions,
.vsp-c-privacy-notice {
    
    .vsp-c-terms-and-conditions__tabs {
        background-color: $vsp-color-white-dark;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 1rem;
        transition: all .2s ease-out;

        @media (max-width: $screen-sm) {
            justify-content: center;
            flex-direction: column;
        }

        nav {
            display: flex;

            a {
                color: $vsp-color-base;
                padding: 1.5rem .35rem;
                margin: 0 1.5rem;
                border-bottom: 2px solid transparent;
                font-weight: 600;
                transition: all .1s ease-out;

                &:hover,
                &.active {
                    border-bottom: 2px solid $vsp-color-primary;
                }

                &.active {
                    color: $vsp-color-primary-dark;
                }

                @media (max-width: $screen-sm) {
                    margin: 0 .5rem;
                    font-size: .9rem;
                }
            }
        }

        &.vsp-c-is-scrolled {
            top: 170px;

            @media (max-width: $screen-lg - 1) {
                top: 133px;
            }
        }
    }

    .vsp-c-terms-and-conditions__content {
        padding: 4rem;

        h1 {
            font-weight: 300;
            padding: 4rem 0;
            text-align: center;

            @media (max-width: $screen-md) {
                font-size: 3rem;
            }
        }

        h2 {
            font-size: 2.5rem;
            font-weight: 900;
            color: $vsp-color-red;
            margin: 4rem 0 2rem 0;
            text-transform: uppercase;
        }

        h3 {
            font-size: 1.5rem;
            font-weight: 600;
            color: $vsp-color-gray-dark;
            margin: 2rem 0 .5rem 0;
        }

        p {
            font-size: 14px;
        }

        li {
            font-weight: 300;
            li {
                padding-left: 1rem;
            }
        }

        .vsp-c-terms-and-conditions__table {
            position: relative;
            table,
            thead,
            tbody {
                border-collapse: collapse;
            }
            table {
                border: 1px solid $dashboard-border-color;
                width: 100%;
                
                thead { 
                    line-height: 40px;
                }
                tbody {

                    tr {
                        border-top: 1px solid $dashboard-border-color;
                        text-align: center;
                    }
                    td {
                        padding: 10px 1rem;
                    }
                }
            }

            @media (max-width: $screen-md) {

                table,
                thead, 
                tbody, 
                th, 
                td, 
                tr { 
                    display: block;
                }

                table,
                td {
                    border: none;
                }
                table {

                    thead tr { 
                        position: absolute;
                        top: -9999px;
                        left: -9999px;
                    }
                    tbody {

                        tr { 
                            border: 1px solid $dashboard-border-color;
                            margin-bottom: 2.5rem;
                        }
                        
                        td { 
                            border-bottom: 1px solid $dashboard-border-color; 
                            position: relative;
                            padding: 10px 10px 25px 50%;
                        }
                        
                        td:before { 
                            position: absolute;
                            top: 10px;
                            left: 1rem;
                            width: 45%; 
                            text-transform: uppercase;
                            color: $vsp-color-primary-dark;
                        }
                        
                        td:nth-of-type(1):before {
                            content: "Record name";
                        }
        
                        td:nth-of-type(2):before { 
                            content: "Storage location"; 
                        }
                        
                        td:nth-of-type(3):before { 
                            content: "Person responsible for storage"; 
                        }
                        
                        td:nth-of-type(4):before { 
                            content: "Controls for record protection"; 
                        }
                        
                        td:nth-of-type(5):before { 
                            content: "Retention time"; 
                        }
                    }
                }
            }
        }
    }

    .vsp-c-privacy-notice {
        padding: 4rem;
    }
}